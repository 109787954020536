import { render, staticRenderFns } from "./AdminAuthorization.vue?vue&type=template&id=6506af69&scoped=true"
import script from "./AdminAuthorization.vue?vue&type=script&lang=js"
export * from "./AdminAuthorization.vue?vue&type=script&lang=js"
import style0 from "./AdminAuthorization.vue?vue&type=style&index=0&id=6506af69&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6506af69",
  null
  
)

export default component.exports