import { render, staticRenderFns } from "./AdminTransactionsUpload.vue?vue&type=template&id=fe2d3b5c&scoped=true"
import script from "./AdminTransactionsUpload.vue?vue&type=script&lang=js"
export * from "./AdminTransactionsUpload.vue?vue&type=script&lang=js"
import style0 from "./AdminTransactionsUpload.vue?vue&type=style&index=0&id=fe2d3b5c&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe2d3b5c",
  null
  
)

export default component.exports